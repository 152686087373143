import React from "react";
import styled from "styled-components";
import FooterInstagramIcon from "./icons/FooterInstagramIcon.png";
import FooterFacebookIcon from "./icons/FooterFacebookIcon.png";
import FooterLinkdinIcon from "./icons/FooterLinkdinIcon.png";

const FooterWrapper = styled.div`
  /* width: 100%; */
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 0;

  & .flex-wrapper {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    @media (max-width: 1224px) {
      width: 90%;
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
      gap: 1.25rem;
    }
    & img {
      width: 50%;
      @media (max-width: 1224px) {
        width: 100%;
      }
    }
  }
  & .flex-spaced {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: unset;
    padding: 2.5rem 0;
    @media (max-width: 1224px) {
      align-items: unset;
      flex-direction: column;
      gap: 2.5rem;
    }
  }
  & .flex-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 1.25rem;
    color: #fff;
  }
  & .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.25rem;
    color: #fff;
    & svg {
      width: 20px;
      height: 20px;
    }
    & a {
      color: #fff;
    }
  }
  & .social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.75rem;
    & img {
      margin: 0 20px;
    }
  }
  & h2 {
    margin: 0 0 1.25rem 0;
  }
  & .logo {
    height: 100%;
    width: 100%;

    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    @media (max-width: 1224px) {
      display: none;
    }
  }
`;
function Footer() {
  return (
    <FooterWrapper>
      <div className="flex-wrapper">
        <div className="social">
          <a
            href="https://www.instagram.com/pneuzarco/"
            target="_blank"
            rel="noreferrer"
            alt="instagram"
          >
            <img
              style={{ width: "40px", height: "40px" }}
              src={FooterInstagramIcon}
              alt="instagram"
            />
          </a>
          <a
            href="https://www.facebook.com/pneuzarco"
            target="_blank"
            rel="noreferrer"
            alt="facebook"
          >
            <img
              style={{ width: "40px", height: "40px" }}
              src={FooterFacebookIcon}
              alt="facebook"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/pneuzarco/"
            target="_blank"
            rel="noreferrer"
            alt="linkdin"
          >
            <img
              style={{ width: "40px", height: "40px" }}
              src={FooterLinkdinIcon}
              alt="linkdin"
            />
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
