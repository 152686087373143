import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import "./App.css";
import { ReactComponent as Logo } from "./components/icons/logo.svg";
import PageImage from "./components/icons/landing_page.svg";
import PageImage_Mobile from "./components/icons/landing_page2.svg";
import Footer from "./components/Footer";
import moment from "moment";
const NavBar = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  background: transparent;
  box-sizing: border-box;
  padding: 0 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1224px) {
    padding: 0 1.25rem;
  }
  & .soonwrap {
    height: 70px;

    & img {
      object-fit: contain;
      height: 100%;
    }
  }
`;
const BodyWrap = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.isTabletOrMobile ? PageImage_Mobile : PageImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  & .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    & h1,
    h2 {
      margin: 0;
      color: #fff;
      padding: 2rem;
    }
    & .title {
      background: linear-gradient(0deg, #1e73be 0%, rgba(30, 115, 190, 0) 100%);
    }

    & .slogan {
      display: flex;
      justify-content: center;
      gap: 0.375rem;
      background: linear-gradient(
        180deg,
        #1e73be 0%,
        rgba(30, 115, 190, 0) 100%
      );
    }

    & .highlighted {
      color: rgba(255, 204, 0, 1);
    }

    & .contacts {
      & a {
        text-decoration: none;
        cursor: pointer;
        & > h2 {
          padding: 0;
        }
      }
    }
  }

  & .footer {
    background: linear-gradient(
      0deg,
      #1e73be 4.66%,
      rgba(30, 115, 190, 0) 58.8%
    );

    & .title {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 0;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-inline: 5%;
      border-bottom: 0.5px solid #ffffff;
      /* padding: 2rem; */
    }

    & .copyright {
      color: #fff;
      padding: 1rem;
    }
  }
`;
function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div className="App">
      <NavBar>
        <Logo />
      </NavBar>
      <div style={{ width: "100%" }}></div>
      <BodyWrap isTabletOrMobile={isTabletOrMobile}>
        <div className="info">
          <div className="title">
            <h1 style={{ fontSize: "48px" }}>EM MANUTENÇÃO</h1>
          </div>
          <div className="slogan">
            <h2>
              <span className="highlighted">Serviço de qualidade </span>de uma
              equipa técnica, profissional e competente.
            </h2>
          </div>
          <div className="contacts">
            <h2 className="highlighted">Contacte-nos através do email</h2>
            <a
              href="mailto:geral@pneuzarco.pt"
              target={"_blank"}
              rel="noreferrer noopenner"
            >
              <h2>geral@pneuzarco.pt</h2>
            </a>
          </div>
        </div>
        <div className="footer">
          <div className="title">
            <h1 style={{ fontSize: "38px" }}>
              PNEUZARCO, O ESPECIALISTA DO PNEU
            </h1>
          </div>
          <div className="copyright">
            <span>© {moment().format('YYYY')} Pneuzarco. Todos os Direitos Reservados.</span>
          </div>
        </div>
      </BodyWrap>
      
      <Footer />
    </div>
  );
}

export default App;
